// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBJ5dbKA8FwHlszQ3rD4yVhRkVfFeDA7dc",
  authDomain: "cleanweb-app.firebaseapp.com",
  projectId: "cleanweb-app",
  storageBucket: "cleanweb-app.appspot.com",
  messagingSenderId: "39024508775",
  appId: "1:39024508775:web:2737e2fff272f50ca5b9c2",
  measurementId: "G-RKTL5LNZWS"
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);

// Initialize Firestore and export it
export const db = getFirestore(app);

// Also export EmailAuthProvider and GoogleAuthProvider
export { EmailAuthProvider, GoogleAuthProvider };
