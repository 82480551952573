/*
* *******************************************************************************************
*CleanWeb Solutions LLC
* *******************************************************************************************
* 
* Copyright 2024 CleanWeb Solutions
* Created by Bentley Dombrow
* 
* This software is the property of CleanWeb Solutions LLC. It is designed and intended for
* the exclusive use of CleanWeb Solutions and its clients. Redistribution or use in
* source and binary forms, with or without modification, is not permitted without
* express written consent from CleanWeb Solutions LLC.
* 
* *******************************************************************************************

//..........................................................................................................................................................................................................................................................................
//....CCCCCCC....LLLL.......EEEEEEEEEEE....AAAAA.....NNNN...NNNNNWWWW..WWWWW...WWWEEEEEEEEEEEE.BBBBBBBBBB.........SSSSSSS......OOOOOOO.....LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.........LLLL............LLLL..............CCCCCCC....
//...CCCCCCCCC...LLLL.......EEEEEEEEEEE....AAAAA.....NNNNN..NNNN.WWWW..WWWWW..WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSS....OOOOOOOOOO...LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS........LLLL............LLLL.............CCCCCCCCC...
//..CCCCCCCCCCC..LLLL.......EEEEEEEEEEE...AAAAAA.....NNNNN..NNNN.WWWW..WWWWWW.WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS..OOOOOOOOOOOO..LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS.......LLLL............LLLL............CCCCCCCCCCC..
//..CCCC...CCCCC.LLLL.......EEEE..........AAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS..SSSS..OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNNNN.NNNN.SSSSS..SSSS.......LLLL............LLLL............CCCC...CCCC..
//.CCCC.....CCC..LLLL.......EEEE.........AAAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS.......OOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNN.NNNN.SSSSS.............LLLL............LLLL...........CCCC.....CCC..
//.CCCC..........LLLL.......EEEEEEEEEE...AAAAAAAA....NNNNNNNNNNN..WWWWWWWWWWW.WWW..EEEEEEEEEE..BBBBBBBBBBB.......SSSSSSS....OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN..SSSSSSS..........LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE...AAAA.AAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBB.........SSSSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE..AAAAAAAAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBBB..........SSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN.....SSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC.....CCC..LLLL.......EEEE........AAAAAAAAAAA..NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB............SSSSSOOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNNNNNNN........SSSSS......LLLL............LLLL...........CCCC.....CCC..
//..CCCC...CCCCC.LLLL.......EEEE........AAAAAAAAAAA..NNNN.NNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB.....SSSS....SSSS.OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNN.NNNNNN.SSSS....SSSS......LLLL............LLLL............CCCC...CCCC..
//..CCCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA....AAAA..NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBBB.....SSSSSSSSSSSS.OOOOOOOOOOOO..LLLLLLLLLL.UUUUUUUUUUU.....TTTT...IIIII.OOOOOOOOOOOO..NNNN..NNNNN.SSSSSSSSSSSS......LLLLLLLLLL......LLLLLLLLLL......CCCCCCCCCCC..
//...CCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS...OOOOOOOOOO...LLLLLLLLLL..UUUUUUUUU......TTTT...IIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS.......LLLLLLLLLL......LLLLLLLLLL.......CCCCCCCCCC..
//....CCCCCCC....LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN...NNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBB.........SSSSSSSS......OOOOOO.....LLLLLLLLLL...UUUUUUU.......TTTT...IIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS........LLLLLLLLLL......LLLLLLLLLL........CCCCCCC....
//..........................................................................................................................................................................................................................................................................
*/


import React, { useEffect, useState } from 'react';
import {
  Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Form, FormGroup, Label, Input,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert, Collapse,  Modal, ModalHeader, ModalBody
} from 'reactstrap';

import { auth, db } from '../../firebase'; // Ensure correct import path
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';



function SupportTickets() {

  //states for the form fields
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState({ id: null, name: '' });
  const [userEmail, setUserEmail] = useState('');
  const [ticketType, setTicketType] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [submittedTicketInfo, setSubmittedTicketInfo] = useState(null);

  //state for checking if there were any errors while the user filled out the form
  const [error, setError] = useState('');

  //states for checking whether the actual submission of the form worked and whether it has been succesfully submitted or not
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  //states for checking if the user clicked on the more info button for a ticket and whether or not to display the modal
  const [modal, setModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [websiteInfo, setWebsiteInfo] = useState({});

  //states for closing and opeing the accordion menus on the right that show the open and closed tickets
  const [isOpenOpenTickets, setIsOpenOpenTickets] = useState(true);
  const [isOpenClosedTickets, setIsOpenClosedTickets] = useState(false);

  //state for holding the found ticket data
  const [tickets, setTickets] = useState([]);

  //state for grabbing all of the website info for the drop down in the form
  const [websites, setWebsites] = useState([]);

  //state for checking the current path of the URL and whether or not the user is already requesting to see more info
  //on a specific ticket with the link they are using
  const location = useLocation();

  useEffect(() => {

    const ticketId = extractTicketIdFromPath(location.pathname);
    if (ticketId) {
      const fetchTicketAndShowModal = async (ticketId) => {
        const ticketRef = doc(db, "tickets", ticketId);
        const docSnap = await getDoc(ticketRef);
  
        if (docSnap.exists()) {
          setSelectedTicket({ id: docSnap.id, ...docSnap.data() });
          fetchWebsiteInfo(docSnap.data().website_id); // Assuming this function is already defined
          setModal(true);
        } else {
          console.log("No such ticket!");
        }
      };
  
      fetchTicketAndShowModal(ticketId);
    }

    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        fetchTickets(user.uid);
        fetchWebsites(user.uid)
        setUserEmail(user.email)
      } else {
        // Handle user not signed in or session expired
        console.log("No user is signed in.");
        setTickets([]); // Optionally clear tickets or handle as needed
      }
    });

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);

  const fetchTickets = async (userUid) => {
    const ticketsQuery = query(collection(db, "tickets"), where("user_id", "==", userUid));
    const ticketsSnapshot = await getDocs(ticketsQuery);

    let userTickets = [];
    ticketsSnapshot.forEach(doc => {
      userTickets.push({ id: doc.id, ...doc.data() });
    });
    setTickets(userTickets);
  };

  const fetchWebsites = async (userUid) => {
    const userProjectsQuery = query(collection(db, "projects"), where("clientUID", "==", userUid));
    const projectDocs = await getDocs(userProjectsQuery);

    let userWebsites = [];
    for (const doc of projectDocs.docs) {
      const projectWebsitesQuery = query(collection(db, "websites"), where("project_id", "==", doc.id));
      const websiteDocs = await getDocs(projectWebsitesQuery);
      websiteDocs.forEach(websiteDoc => {
        userWebsites.push({
          id: websiteDoc.id,
          project: doc.data().project_name,
          projectDescription: doc.data().description,
          projectStatus: doc.data().status,
          startDate: doc.data().start_date,
          endDate: doc.data().end_date,
          ...websiteDoc.data()
        });
      });
    }
    setWebsites(userWebsites);
  };

  // Style object for the dropdown toggle buttons
  const dropdownButtonStyle = {
    backgroundColor: '#6495ED',
    backgroundImage: 'none',
    borderColor: 'cornflowerblue',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  };

  const iconStyle = {
    transition: 'transform 0.3s ease-in-out',
  };

  const ticketPurposes = ['Update Request', 'Website Issue Support', 'Billing Support', 'Other'];

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const validateForm = () => {
    let errors = {};
    if (!subject) errors.subject = 'Subject required';
    if (!description) errors.description = 'Description required';
    if (!ticketType) errors.ticketType = 'Ticket purpose required';
    if (ticketType === 'Other' && !otherReason) errors.otherReason = 'Other reason required';
    if (!userEmail) errors.userEmail = 'Email is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is signed in
    if (!auth.currentUser) {
      setError('You must be signed in to submit a ticket.');
      return;
    }
    
    if (!validateForm()) {
      setError('Please fill in all required fields.');
      return;
    }
    
    console.log(selectedWebsite)

    if (!selectedWebsite){
      setSelectedWebsite({ id: "ALERT: No Website Specified", name: "ALERT: No Website Specified" })
    }

    console.log("Before performing the ticketData data mapping: " + selectedWebsite);
    // Assuming form state or variables hold all necessary ticket information
    const ticketData = {
      creation_date: new Date(), // Firestore Timestamp format
      description: description,
      email: userEmail, // Assuming userEmail is fetched from auth state or form
      reason: ticketType,
      resolution_date: null, // or Firestore's serverTimestamp if applicable
      status: "Open",
      subject: subject,
      user_id: auth.currentUser.uid, // Directly using Firebase auth state
      website_id: selectedWebsite.id, // Ensure this is set correctly from dropdown
      website_name: selectedWebsite.name
    };

    console.log("After Performing the ticketData data mapping " + ticketData);
  
    try {
      // Submit the ticket to Firestore
      const docRef = await addDoc(collection(db, "tickets"), ticketData);
      console.log("Document written with ID: ", docRef.id);

      // Store the submitted ticket data along with the generated ID
      setSubmittedTicketInfo({
        ...ticketData,
        id: docRef.id,
      });
        
      // Send email notification
      const functionUrl = 'https://us-central1-cleanweb-app.cloudfunctions.net/CleanWebAPI/sendTicketNotification'; // Replace with your actual Cloud Function URL
      await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: "support@clean-web.solutions", // destination email address
          subject: `New Support Ticket At ID ${ticketData.id} Submitted: ${ticketData.subject}.`,
          message: `A new ticket has been submitted.\n\nDetails:\nID: ${ticketData.id}\nSubject: ${ticketData.subject}\nDescription: ${ticketData.description}\nEmail: ${ticketData.email}\n Reason: ${ticketData.reason}\n User ID: ${ticketData.user_id} \n Website ID + Name: ${ticketData.website_id} + ${ticketData.website_name}\nThis is an automated message.`,
        }),
      });
  
      // Update UI to reflect success
      setSuccess(true);
      // Optionally clear the form or give feedback to the user
    } catch (error) {
      console.error("Error adding document: ", error);
      setError('An error occurred while submitting the ticket.');
    }
  };

  const extractTicketIdFromPath = (path) => {
    const match = path.match(/\/tickets\/([^\/]+)/);
    return match ? match[1] : null;
  };

  const renderError = (field) => {
    return formErrors[field] ? <div style={{ color: 'red', fontSize: '0.8em', marginTop: '20px', marginBottom: '20px' }}>{formErrors[field]}</div> : null;
  };

  const getBorderStyle = (fieldName) => {
    return formErrors[fieldName] ? { borderColor: 'red', borderWidth: '2px' } : {};
  };

  const renderTicketSubmission = () => {
    if (!submittedTicketInfo) return null; // Guard clause in case the data isn't there yet

    const handleReturnClick = () => {
      // Reloads the current page from the server
      window.location.reload();
      setSuccess(false)
    };
  
    return (
      <div className="content">
        <h2>Your Ticket Has Been Received!</h2>
        <hr style={{ borderColor: 'cornflowerblue', borderWidth: '2px' }} />
        <p>We have received your ticket and our team will get back to you shortly. Please check your email for further updates.</p>
        <hr style={{ borderColor: 'cornflowerblue', borderWidth: '2px' }} />
        <h3>General Information</h3>
        <ul>
          <li>Ticket ID: {submittedTicketInfo.id}</li>
          <li>Subject: {submittedTicketInfo.subject}</li>
          <li>Description: {submittedTicketInfo.description}</li>
          <li>Email: {submittedTicketInfo.email}</li>
          <li>Reason for Ticket: {submittedTicketInfo.reason}</li>
          <li>Status: {submittedTicketInfo.status}</li>
          {/* Display website name if available */}
          {submittedTicketInfo.website_name && <li>Website: {submittedTicketInfo.website_name}</li>}
        </ul>
        <Button onClick={handleReturnClick}>Return</Button>
      </div>
    );
  };

  const renderTicket = (ticket) => {
    // Function to format date
    const formatDate = (date) => {
      if (!date) return 'Unknown'; // Handle null or undefined
      if (typeof date === 'string') return date; // Handle if already a string
      // Handle Firestore timestamp
      if (date.seconds) {
        return new Date(date.seconds * 1000).toLocaleDateString();
      }
      return 'Invalid Date'; // Fallback for any other case
    };
  
    const creationDate = formatDate(ticket.creation_date);
  
    return (
      <Card key={ticket.id} style={{ backgroundColor: '#222222', marginBottom: '10px' }}>
        <CardBody>
          <p><strong>Subject:</strong> {ticket.subject}</p>
          <p><strong>Status:</strong> <span style={{ color: getStatusColor(ticket.status) }}>{ticket.status}</span></p>
          <p><strong>Date:</strong> {creationDate}</p>
          <Button color="primary" onClick={() => handleViewDetails(ticket)}>View Details</Button>
        </CardBody>
      </Card>
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Open': return 'cornflowerblue';
      case 'In Progress': return 'orange';
      case 'Closed': return 'green';
      default: return 'grey';
    }
  };

  const noTicketsStyle = {
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
  };

  if (success) {
    return renderTicketSubmission();
  }

  const toggleModal = () => {
    setModal(!modal);
  };

  const fetchWebsiteInfo = async (websiteId) => {
    if (!websiteId) {
      setWebsiteInfo({ error: "No website ID provided." });
      return;
    }
    try {
      const docRef = doc(db, "websites", websiteId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setWebsiteInfo(docSnap.data());
      } else {
        setWebsiteInfo({ error: "Website information not found." });
      }
    } catch (error) {
      setWebsiteInfo({ error: "Failed to fetch website information." });
    }
  };

  const handleViewDetails = (ticket) => {
    setSelectedTicket(ticket);
    fetchWebsiteInfo(ticket.website_id);
    toggleModal();
  };

    // Modal Component for displaying selected ticket details
    const TicketDetailsModal = () => (
      <Modal isOpen={modal} toggle={toggleModal} style={{ backgroundColor: '#222222' }}>
        <ModalHeader toggle={toggleModal} style={{ backgroundColor: '#222222', color: 'white !important', padding: '24px', fontSize: '20px' }}><span style={{fontSize: '20px'}}>Ticket Details</span></ModalHeader>
        <ModalBody style={{ backgroundColor: '#222222', color: 'white' }}>
          {selectedTicket && (
            <>
              <p><strong style={{ color: 'cornflowerblue' }}>Subject:</strong> <span style={{ color: 'white' }}>{selectedTicket.subject}</span></p>
              <p><strong style={{ color: 'cornflowerblue' }}>Description:</strong> <span style={{ color: 'white' }}>{selectedTicket.description}</span></p>
              <p><strong style={{ color: 'cornflowerblue' }}>Status:</strong> <span style={{ color: 'white' }}>{selectedTicket.status}</span></p>
              <p><strong style={{ color: 'cornflowerblue' }}>Email Used:</strong> <span style={{ color: 'white' }}>{selectedTicket.email}</span></p>
              <p><strong style={{ color: 'cornflowerblue' }}>Reason For Ticket:</strong> <span style={{ color: 'white' }}>{selectedTicket.reason}</span></p>
              {/* Display website information or error message */}
              {websiteInfo.error ? (
                <p style={{ color: 'white' }}>{websiteInfo.error}</p>
              ) : (
                <>
                  <p><strong style={{ color: 'cornflowerblue' }}>Website Name:</strong> <span style={{ color: 'white' }}>{websiteInfo.website_name}</span></p>
                  <p><strong style={{ color: 'cornflowerblue' }}>Website URL:</strong> <span style={{ color: 'white' }}>{websiteInfo.url}</span></p>
                </>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    );


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <CardTitle tag="h2">Support and Assistance</CardTitle>
              <span>* Indicates Required Field</span>
            </CardHeader>
            <CardBody>
              {error && <Alert color="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit} noValidate>
                {/* Website Dropdown */}
                <FormGroup>
                  <Label for="websiteSelect" style={{ fontSize: '1.1em' }}>Select Website (Optional)</Label>
                  <Dropdown color="primary" isOpen={dropdownOpen} toggle={toggleDropdown} style={{ color: 'white' }}>
                    <DropdownToggle caret style={{color: 'white' }}>
                      {selectedWebsite.name || 'Select Website'}
                    </DropdownToggle>
                    <DropdownMenu style={{ backgroundColor: '#222222', color: 'cornflowerblue' }}>
                      {websites.map(website => (
                        <DropdownItem key={website.id} onClick={() => setSelectedWebsite({ id: website.id, name: website.website_name })} style={{ color: 'cornflowerblue' }}>
                          {website.website_name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>

                {/* Email Field */}
                <FormGroup>
                  <Label for="email" style={{ fontSize: '1.1em' }}>Your Email *</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    style={getBorderStyle('userEmail')}
                    placeholder="Enter your email here"
                  />
                  {renderError('userEmail')}
                </FormGroup>

                {/* Ticket Purpose */}
                <FormGroup>
                  <Label for="ticketPurpose" style={{ fontSize: '1.1em' }}>Reason For Ticket *</Label>
                  <div>
                    {ticketPurposes.map((purpose, index) => (
                      <FormGroup check key={index}>
                        <Label check style={{ fontSize: '1.1em' }}>
                          <Input
                            type="radio"
                            name="ticketPurpose"
                            value={purpose}
                            onChange={(e) => setTicketType(e.target.value)}
                            style={getBorderStyle('ticketType')}
                          />
                          {purpose}
                        </Label>
                      </FormGroup>
                    ))}
                    {ticketType === 'Other' && (
                      <>
                        <Input
                          type="text"
                          value={otherReason}
                          onChange={(e) => setOtherReason(e.target.value)}
                          placeholder="Please specify"
                          style={{ ...getBorderStyle('otherReason'), marginTop: '10px' }}
                        />
                        {renderError('otherReason')}
                      </>
                    )}
                  </div>
                  {renderError('ticketType')}
                </FormGroup>

                {/* Subject Field */}
                <FormGroup>
                  <Label for="subject" style={{ fontSize: '1.1em' }}>Subject *</Label>
                  <Input
                    type="text"
                    name="subject"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={getBorderStyle('subject')}
                    placeholder="Enter subject here"
                  />
                  {renderError('subject')}
                </FormGroup>

                {/* Description Field */}
                <FormGroup>
                  <Label for="description" style={{ fontSize: '1.1em' }}>Description *</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={getBorderStyle('description')}
                    placeholder="Enter description here"
                  />
                  {renderError('description')}
                </FormGroup>

                <Button type="submit" color="primary">Submit Ticket</Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md='4'>
        <Card>
          <CardHeader style={{ backgroundColor: '#6495ED' }}>
            <CardTitle tag="h4">Ticket History</CardTitle>
          </CardHeader>
          <CardBody style={{ overflowY: 'auto', maxHeight: '500px' }}>
            <div>
              <Button style={dropdownButtonStyle} color="primary" onClick={() => setIsOpenOpenTickets(!isOpenOpenTickets)}>
                Open Tickets 
                <i className="tim-icons icon-minimal-down" style={{ ...iconStyle, transform: isOpenOpenTickets ? 'rotate(-180deg)' : 'none' }} />
              </Button>
              <Collapse isOpen={isOpenOpenTickets}>
                {tickets.filter(ticket => ticket.status === 'Open' || ticket.status === 'In Progress').length > 0 ? (
                  tickets.filter(ticket => ticket.status === 'Open' || ticket.status === 'In Progress').map(renderTicket)
                ) : (
                  <p style={noTicketsStyle}>There are currently no open tickets associated with this account.</p>
                )}
              </Collapse>
            </div>
            <div>
              <Button style={dropdownButtonStyle} color="primary" onClick={() => setIsOpenClosedTickets(!isOpenClosedTickets)}>
                Closed Tickets 
                <i className="tim-icons icon-minimal-down" style={{ ...iconStyle, transform: isOpenClosedTickets ? 'rotate(-180deg)' : 'none' }} />
              </Button>
              <Collapse isOpen={isOpenClosedTickets}>
                {tickets.filter(ticket => ticket.status === 'Closed').length > 0 ? (
                  tickets.filter(ticket => ticket.status === 'Closed').map(renderTicket)
                ) : (
                  <p style={noTicketsStyle}>There are currently no closed tickets associated with this account.</p>
                )}
              </Collapse>
            </div>
          </CardBody>
        </Card>
      </Col>
      </Row>
      <TicketDetailsModal />
    </div>
  );
}

export default SupportTickets;
