/*
* *******************************************************************************************
*CleanWeb Solutions LLC
* *******************************************************************************************
* 
* Copyright 2024 CleanWeb Solutions
* Created by Bentley Dombrow
* 
* This software is the property of CleanWeb Solutions LLC. It is designed and intended for
* the exclusive use of CleanWeb Solutions and its clients. Redistribution or use in
* source and binary forms, with or without modification, is not permitted without
* express written consent from CleanWeb Solutions LLC.
* 
* *******************************************************************************************

//..........................................................................................................................................................................................................................................................................
//....CCCCCCC....LLLL.......EEEEEEEEEEE....AAAAA.....NNNN...NNNNNWWWW..WWWWW...WWWEEEEEEEEEEEE.BBBBBBBBBB.........SSSSSSS......OOOOOOO.....LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.........LLLL............LLLL..............CCCCCCC....
//...CCCCCCCCC...LLLL.......EEEEEEEEEEE....AAAAA.....NNNNN..NNNN.WWWW..WWWWW..WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSS....OOOOOOOOOO...LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS........LLLL............LLLL.............CCCCCCCCC...
//..CCCCCCCCCCC..LLLL.......EEEEEEEEEEE...AAAAAA.....NNNNN..NNNN.WWWW..WWWWWW.WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS..OOOOOOOOOOOO..LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS.......LLLL............LLLL............CCCCCCCCCCC..
//..CCCC...CCCCC.LLLL.......EEEE..........AAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS..SSSS..OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNNNN.NNNN.SSSSS..SSSS.......LLLL............LLLL............CCCC...CCCC..
//.CCCC.....CCC..LLLL.......EEEE.........AAAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS.......OOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNN.NNNN.SSSSS.............LLLL............LLLL...........CCCC.....CCC..
//.CCCC..........LLLL.......EEEEEEEEEE...AAAAAAAA....NNNNNNNNNNN..WWWWWWWWWWW.WWW..EEEEEEEEEE..BBBBBBBBBBB.......SSSSSSS....OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN..SSSSSSS..........LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE...AAAA.AAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBB.........SSSSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE..AAAAAAAAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBBB..........SSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN.....SSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC.....CCC..LLLL.......EEEE........AAAAAAAAAAA..NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB............SSSSSOOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNNNNNNN........SSSSS......LLLL............LLLL...........CCCC.....CCC..
//..CCCC...CCCCC.LLLL.......EEEE........AAAAAAAAAAA..NNNN.NNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB.....SSSS....SSSS.OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNN.NNNNNN.SSSS....SSSS......LLLL............LLLL............CCCC...CCCC..
//..CCCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA....AAAA..NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBBB.....SSSSSSSSSSSS.OOOOOOOOOOOO..LLLLLLLLLL.UUUUUUUUUUU.....TTTT...IIIII.OOOOOOOOOOOO..NNNN..NNNNN.SSSSSSSSSSSS......LLLLLLLLLL......LLLLLLLLLL......CCCCCCCCCCC..
//...CCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS...OOOOOOOOOO...LLLLLLLLLL..UUUUUUUUU......TTTT...IIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS.......LLLLLLLLLL......LLLLLLLLLL.......CCCCCCCCCC..
//....CCCCCCC....LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN...NNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBB.........SSSSSSSS......OOOOOO.....LLLLLLLLLL...UUUUUUU.......TTTT...IIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS........LLLLLLLLLL......LLLLLLLLLL........CCCCCCC....
//..........................................................................................................................................................................................................................................................................
*/

import React, { useEffect, useState } from "react";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledAlert,
  Alert
} from "reactstrap";

import { auth, db } from '../../firebase'; // Correct import path
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

import { useNavigate } from 'react-router-dom';


import websiteTileSVG from "assets/img/website-tile-background.svg"

function Dashboard(props) {
  const notificationAlertRef = React.useRef(null);

  const [websites, setWebsites] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    const fakeUid = "ofBTrjhIMtPijhMgHbG89H9TuZh1"

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, fetch their projects and websites
        const userUid = user.uid;
        fetchWebsites(userUid);
        fetchTickets(userUid)
        fetchNotifications(userUid);
      } else {
        // User is signed out

        //! RE-ENABLE ONCE DEVELOPMENT IS DONE ! 
        //!-////////////////////////////////////////////////////////////////////////////////////////////////
        console.log("No User Found")
        setWebsites([]);
        setTickets([]);
        setNotifications([]);
      }
    });

    return () => {
      // Unsubscribe from auth changes when component unmounts
      unsubscribe();
    }
  }, []); // Empty dependency array to run only once after initial render

  const fetchWebsites = async (userUid) => {
    const userProjectsQuery = query(collection(db, "projects"), where("clientUID", "==", userUid));
    const projectDocs = await getDocs(userProjectsQuery);

    let userWebsites = [];
    for (const doc of projectDocs.docs) {
      const projectWebsitesQuery = query(collection(db, "websites"), where("project_id", "==", doc.id));
      const websiteDocs = await getDocs(projectWebsitesQuery);
      websiteDocs.forEach(doc => {
        const websiteData = { id: doc.id, ...doc.data() };
        // Check website status and update it
        checkWebsiteStatus(websiteData.url)
          .then(status => {
            websiteData.status = status;
            updateWebsiteStatus(websiteData);
          })
          .catch(error => {
            console.error("Error checking website status:", error);
          });
        userWebsites.push(websiteData);
      });
    }
    setWebsites(userWebsites);
  };

  const fetchTickets = async (userUid) => {
    const ticketsQuery = query(collection(db, "tickets"), where("user_id", "==", userUid));
    const ticketsSnapshot = await getDocs(ticketsQuery);

    let userTickets = [];
    ticketsSnapshot.forEach(doc => {
      userTickets.push({ id: doc.id, ...doc.data() });
    });
    setTickets(userTickets);
  };

  const fetchNotifications = async (userUid) => {
    const notificationsQuery = query(collection(db, "notifications"), where("user_id", "==", userUid), where("dismissed", "==", false));
    const notificationDocs = await getDocs(notificationsQuery);

    let userNotifications = [];
    notificationDocs.forEach(doc => {
      userNotifications.push({ id: doc.id, ...doc.data() });
    });
    setNotifications(userNotifications);
  };

  const checkWebsiteStatus = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'HEAD', // Use HEAD request for a simple check
      });
  
      if (response.status === 200) {
        return 'Online';
      } else {
        return 'Offline';
      }
    } catch (error) {
      console.error(`Error checking status for ${url}:`, error);
      return 'Error';
    }
  };

  const updateWebsiteStatus = (index, status) => {
    setWebsites(currentWebsites => {
      // Create a new array with updated status
      return currentWebsites.map((website, i) =>
        i === index ? { ...website, status: status } : website
      );
    });
  };

  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        
        {/* Websites Section */}
        <Row>
          <Col xs="12">
            <h2 style={{ fontFamily: 'sans-serif', fontWeight: 'lighter', fontSize: '24px', color: 'white', marginBottom: '20px' }}>Websites Overview</h2>
          </Col>
        </Row>
        <Row>
          {websites.length > 0 ? (
            websites.map((website, index) => (
              <Col lg="4" md="6" sm="12" key={index}>
                <Card style={{ backgroundImage: `url(${websiteTileSVG})` }}>
                  <CardHeader>
                    <CardTitle tag="h4">{website.website_name}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p>URL: <a href={website.url}>{website.url}</a></p>
                    <p>Status: {website.status}</p>
                    <Button color="primary" href={`/my-websites`}>More Info</Button>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <Col>
              <Card>
                <CardBody> <p>There are currently no websites associated with this account. </p> </CardBody>
              </Card>
            </Col>
          )}
        </Row>

        {/* Tickets Section */}
        <Row>
          <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Your Tickets</CardTitle>
              </CardHeader>
              <CardBody>
                {tickets.length > 0 ? (
                  <div style={{ overflowX: 'auto', overflowY: 'hidden', marginBottom: '1rem' }}>
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>Subject</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tickets.map((ticket, index) => (
                          <tr key={index}>
                            <td>{ticket.subject.length > 50 ? ticket.subject.slice(0, 200) + "..." : ticket.subject}</td>
                            <td>{ticket.description.length > 200 ? ticket.description.slice(0, 200) + "..." : ticket.description}</td>
                            <td>{ticket.status}</td>
                            <td><Button color="primary" onClick={() => navigate(`/tickets/${ticket.id}`)}>More Info</Button></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <p>There are currently no tickets associated with this account.</p>
                )}
              </CardBody>
            </Card>
          </Col>

          {/* Notifications Section */}
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Your Notifications</CardTitle>
              </CardHeader>
              <CardBody>
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <UncontrolledAlert key={index} className="alert-with-icon" color="info">
                      <span className="tim-icons icon-bell-55" data-notify="icon" />
                      <span data-notify="message">
                        <b>{notification.title} - </b> {notification.description}
                      </span>
                    </UncontrolledAlert>
                  ))
                ) : (
                  <p>There are currently no notifications associated with this account.</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
