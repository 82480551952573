
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import logoImage from "../../assets/clean-web-logos/logo-blue.png"; 

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";

function DashboardNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  //updated styling for the navbar so that the icons are actually properly centered
  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px'
  };

  const handleProfileInfoClick = () => {
    // Use navigate to navigate to the desired location
    navigate("/profile-info");
  };
  
  const handleLogoutClick = () => {
    navigate("/logout");
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  return (
    <>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>

          </div>
          {/* <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown>
                <DropdownToggle caret data-toggle="dropdown" style={{width: "250px", height: "50px"}}>
                  <i className="tim-icons icon-single-02" style={{marginRight: "20px", marginBottom: "5px"}}></i>
                  UserName
                </DropdownToggle>
                <DropdownMenu className="dropdown-black" style={{width: "250px"}}>
                  <DropdownItem onClick={handleProfileInfoClick}>
                  <a href="/profile-info" className="dropdown-item-link">
                    <i className="tim-icons icon-settings-gear-63"></i>
                    Profile Info
                  </a>
                  </DropdownItem>
                  <DropdownItem onClick={handleLogoutClick}>
                  <i className="tim-icons icon-upload"></i>
                  Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse> */}

          {/* <NavbarBrand href="/" onClick={(e) => e.preventDefault()}>
              <img src={logoImage} alt="Your Logo" style={{width: "75px", marginTop: "50px"}}/>
            </NavbarBrand> */}

        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default DashboardNavbar;
