/*
* *******************************************************************************************
*CleanWeb Solutions LLC
* *******************************************************************************************
* 
* Copyright 2024 CleanWeb Solutions
* Created by Bentley Dombrow
* 
* This software is the property of CleanWeb Solutions LLC. It is designed and intended for
* the exclusive use of CleanWeb Solutions and its clients. Redistribution or use in
* source and binary forms, with or without modification, is not permitted without
* express written consent from CleanWeb Solutions LLC.
* 
* *******************************************************************************************

//..........................................................................................................................................................................................................................................................................
//....CCCCCCC....LLLL.......EEEEEEEEEEE....AAAAA.....NNNN...NNNNNWWWW..WWWWW...WWWEEEEEEEEEEEE.BBBBBBBBBB.........SSSSSSS......OOOOOOO.....LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.........LLLL............LLLL..............CCCCCCC....
//...CCCCCCCCC...LLLL.......EEEEEEEEEEE....AAAAA.....NNNNN..NNNN.WWWW..WWWWW..WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSS....OOOOOOOOOO...LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS........LLLL............LLLL.............CCCCCCCCC...
//..CCCCCCCCCCC..LLLL.......EEEEEEEEEEE...AAAAAA.....NNNNN..NNNN.WWWW..WWWWWW.WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS..OOOOOOOOOOOO..LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS.......LLLL............LLLL............CCCCCCCCCCC..
//..CCCC...CCCCC.LLLL.......EEEE..........AAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS..SSSS..OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNNNN.NNNN.SSSSS..SSSS.......LLLL............LLLL............CCCC...CCCC..
//.CCCC.....CCC..LLLL.......EEEE.........AAAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS.......OOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNN.NNNN.SSSSS.............LLLL............LLLL...........CCCC.....CCC..
//.CCCC..........LLLL.......EEEEEEEEEE...AAAAAAAA....NNNNNNNNNNN..WWWWWWWWWWW.WWW..EEEEEEEEEE..BBBBBBBBBBB.......SSSSSSS....OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN..SSSSSSS..........LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE...AAAA.AAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBB.........SSSSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE..AAAAAAAAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBBB..........SSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN.....SSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC.....CCC..LLLL.......EEEE........AAAAAAAAAAA..NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB............SSSSSOOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNNNNNNN........SSSSS......LLLL............LLLL...........CCCC.....CCC..
//..CCCC...CCCCC.LLLL.......EEEE........AAAAAAAAAAA..NNNN.NNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB.....SSSS....SSSS.OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNN.NNNNNN.SSSS....SSSS......LLLL............LLLL............CCCC...CCCC..
//..CCCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA....AAAA..NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBBB.....SSSSSSSSSSSS.OOOOOOOOOOOO..LLLLLLLLLL.UUUUUUUUUUU.....TTTT...IIIII.OOOOOOOOOOOO..NNNN..NNNNN.SSSSSSSSSSSS......LLLLLLLLLL......LLLLLLLLLL......CCCCCCCCCCC..
//...CCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS...OOOOOOOOOO...LLLLLLLLLL..UUUUUUUUU......TTTT...IIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS.......LLLLLLLLLL......LLLLLLLLLL.......CCCCCCCCCC..
//....CCCCCCC....LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN...NNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBB.........SSSSSSSS......OOOOOO.....LLLLLLLLLL...UUUUUUU.......TTTT...IIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS........LLLLLLLLLL......LLLLLLLLLL........CCCCCCC....
//..........................................................................................................................................................................................................................................................................
*/


import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  Alert,
  Row,
  Col,
} from "reactstrap";
import { onAuthStateChanged, sendPasswordResetEmail, updateEmail } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

import profilePic from "../../assets/img/profile-pic-example.jpg";
import ProfilePictureUploader from "./ProfilePictureUploader/ProfilePictureUploader";
import { auth, db } from "../../firebase"; // Adjust the import path as needed

function ProfileSettings() {
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    created_at: ""
  });

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          fetchUserData(uid);
        } else {
          console.log("No user is signed in.");
        }
      });
  
      return () => unsubscribe(); // Cleanup on unmount
    }, []);

  async function fetchUserData(userUID) {
    try {
      const userDocRef = doc(db, "users", userUID);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const firestoreData = userDocSnap.data();

        // Manually map Firestore data to state
        const mappedUserData = {
          firstName: firestoreData.firstName || "",
          lastName: firestoreData.lastName || "",
          email: firestoreData.email || "",
          created_at: formatDate(firestoreData.created_at) || ""
          // Add more mappings if needed
        };

        setUserData(mappedUserData); // Update the state with mapped data
        console.log(mappedUserData)
      } else {
        console.error("No such document for the user:", userUID);
      }
    } catch (error) {
      console.error("Error fetching user document:", error);
    }
  }

  const formatDate = (date) => {
    if (!date) return 'Unknown'; // Handle null or undefined
    if (typeof date === 'string') return date; // Handle if already a string
    // Handle Firestore timestamp
    if (date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return 'Invalid Date'; // Fallback for any other case
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, userData.email);
      setShowPasswordAlert(true);
      setTimeout(() => setShowPasswordAlert(false), 5000);
    } catch (error) {
      console.error("Error sending password reset email:", error);
    }
  };

  const handleEmailReset = async () => {
    if (!showEmailInput) {
      // Show the email input field and confirm button
      setShowEmailInput(true);
      setShowConfirmButton(true);
    } else {
      try {
        // Perform email validation
        if (!isValidEmail(newEmail)) {
          console.error("Invalid email format.");
          // Show an alert to the user
          alert("Invalid email format. Please enter a valid email address.");
          return;
        }
  
        // Implement email update logic here
        // For example, you can use updateEmail function from Firebase
        await updateEmail(auth.currentUser, newEmail);
  
        setShowEmailAlert(true);
        setShowEmailInput(false); // Hide the input field after successful update
        setShowConfirmButton(false); // Hide the confirmation button
        setNewEmail(""); // Clear the input field
      } catch (error) {
        console.error("Error updating email:", error);
        // Show an alert to the user with the error message
        alert("Error updating email. Please try again later.");
      }
    }
  };
  

  const isValidEmail = (email) => {
    // Implement email validation logic here
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const resetButtonStyle = {
    marginTop: "10px",
    marginBottom: "10px",
  };

  return (
    <div className="content">
      <div style={{ display: "flex", gap: "1%" }}>
        <Card>
          <CardHeader>
            <CardTitle><h2>General Info</h2></CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <p style={{marginBottom: '15px', fontSize: '16px'}}><strong>First Name:</strong> {userData.firstName}</p>
                <p style={{marginBottom: '15px', fontSize: '16px'}}><strong>Last Name:</strong> {userData.lastName}</p>
                <p style={{marginBottom: '15px', fontSize: '16px'}}><strong>Email:</strong> {userData.email}</p>
                <p style={{marginBottom: '15px', fontSize: '16px'}}><strong>Account Creation Date:</strong> {userData.created_at}</p>
              </Col>
            </Row>

            {showEmailInput ? (
              <Row>
                <Col style={{display: "flex", alignContent: "center", maxWidth: "20vw"}}>
                  <input
                    type="email"
                    placeholder="Enter new email"
                    value={newEmail}
                    class="form-control"
                    style={{margin: "auto"}}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                </Col>
                {showConfirmButton && (
                  <Col>
                    <Button
                      style={{ marginTop: "10px", marginLeft: "5px" }}
                      color="primary"
                      onClick={handleEmailReset}
                    >
                      Confirm Email Reset
                    </Button>
                  </Col>
                )}
              </Row>
            ) : (
              <Row>
                <Col>
                  <Button style={resetButtonStyle} color="primary" onClick={handleEmailReset}>
                    {showEmailAlert ? "Confirm Email Change" : "Reset Email"}
                  </Button>
                  {showEmailAlert && (
                    <Alert color="success" style={{ marginTop: "15px" }}>
                      A confirmation email was sent to your inbox. Please check your email to complete the change.
                    </Alert>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Button style={resetButtonStyle} color="primary" onClick={handlePasswordReset}>Reset Password</Button>
                {showPasswordAlert && (
                  <Alert color="success" style={{ marginTop: "15px" }}>
                    A password reset request was sent to your inbox. Please check your email to continue.
                  </Alert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ProfileSettings;
