/*
* *******************************************************************************************
*CleanWeb Solutions LLC
* *******************************************************************************************
* 
* Copyright 2024 CleanWeb Solutions
* Created by Bentley Dombrow
* 
* This software is the property of CleanWeb Solutions LLC. It is designed and intended for
* the exclusive use of CleanWeb Solutions and its clients. Redistribution or use in
* source and binary forms, with or without modification, is not permitted without
* express written consent from CleanWeb Solutions LLC.
* 
* *******************************************************************************************

//..........................................................................................................................................................................................................................................................................
//....CCCCCCC....LLLL.......EEEEEEEEEEE....AAAAA.....NNNN...NNNNNWWWW..WWWWW...WWWEEEEEEEEEEEE.BBBBBBBBBB.........SSSSSSS......OOOOOOO.....LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII...OOOOOOO.....NNNN...NNNN...SSSSSSS.........LLLL............LLLL..............CCCCCCC....
//...CCCCCCCCC...LLLL.......EEEEEEEEEEE....AAAAA.....NNNNN..NNNN.WWWW..WWWWW..WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSS....OOOOOOOOOO...LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII..OOOOOOOOOO...NNNNN..NNNN..SSSSSSSSS........LLLL............LLLL.............CCCCCCCCC...
//..CCCCCCCCCCC..LLLL.......EEEEEEEEEEE...AAAAAA.....NNNNN..NNNN.WWWW..WWWWWW.WWWW.EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS..OOOOOOOOOOOO..LLLL.......UUUU...UUUU..TTTTTTTTTTIIIII.OOOOOOOOOOOO..NNNNN..NNNN..SSSSSSSSSS.......LLLL............LLLL............CCCCCCCCCCC..
//..CCCC...CCCCC.LLLL.......EEEE..........AAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS..SSSS..OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNNNN.NNNN.SSSSS..SSSS.......LLLL............LLLL............CCCC...CCCC..
//.CCCC.....CCC..LLLL.......EEEE.........AAAAAAAA....NNNNNN.NNNN.WWWW.WWWWWWW.WWWW.EEEE........BBBB...BBBB......SSSSS.......OOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNN.NNNN.SSSSS.............LLLL............LLLL...........CCCC.....CCC..
//.CCCC..........LLLL.......EEEEEEEEEE...AAAAAAAA....NNNNNNNNNNN..WWWWWWWWWWW.WWW..EEEEEEEEEE..BBBBBBBBBBB.......SSSSSSS....OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN..SSSSSSS..........LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE...AAAA.AAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBB.........SSSSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN...SSSSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC..........LLLL.......EEEEEEEEEE..AAAAAAAAAA...NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEEEEEEEE..BBBBBBBBBBB..........SSSSSSS.OOOO......OOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOO......OOOO.NNNNNNNNNNN.....SSSSSSS.......LLLL............LLLL...........CCCC..........
//.CCCC.....CCC..LLLL.......EEEE........AAAAAAAAAAA..NNNNNNNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB............SSSSSOOOOO....OOOOO.LLLL.......UUUU...UUUU.....TTTT...IIIIIOOOOO....OOOOO.NNNNNNNNNNN........SSSSS......LLLL............LLLL...........CCCC.....CCC..
//..CCCC...CCCCC.LLLL.......EEEE........AAAAAAAAAAA..NNNN.NNNNNN..WWWWWWW.WWWWWWW..EEEE........BBBB....BBBB.....SSSS....SSSS.OOOOO..OOOOO..LLLL.......UUUU...UUUU.....TTTT...IIIII.OOOOO..OOOOO..NNNN.NNNNNN.SSSS....SSSS......LLLL............LLLL............CCCC...CCCC..
//..CCCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA....AAAA..NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBBB.....SSSSSSSSSSSS.OOOOOOOOOOOO..LLLLLLLLLL.UUUUUUUUUUU.....TTTT...IIIII.OOOOOOOOOOOO..NNNN..NNNNN.SSSSSSSSSSSS......LLLLLLLLLL......LLLLLLLLLL......CCCCCCCCCCC..
//...CCCCCCCCCC..LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN..NNNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBBB.......SSSSSSSSSS...OOOOOOOOOO...LLLLLLLLLL..UUUUUUUUU......TTTT...IIIII..OOOOOOOOOO...NNNN..NNNNN..SSSSSSSSSS.......LLLLLLLLLL......LLLLLLLLLL.......CCCCCCCCCC..
//....CCCCCCC....LLLLLLLLLL.EEEEEEEEEEEAAAA.....AAAA.NNNN...NNNN...WWWWW...WWWWW...EEEEEEEEEEE.BBBBBBBBBB.........SSSSSSSS......OOOOOO.....LLLLLLLLLL...UUUUUUU.......TTTT...IIIII....OOOOOO.....NNNN...NNNN...SSSSSSSS........LLLLLLLLLL......LLLLLLLLLL........CCCCCCC....
//..........................................................................................................................................................................................................................................................................
*/

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from "reactstrap";

const LoginRequired = () => {
  const navigate = useNavigate();

  return (
    <Container className="mt-5">
      <h1>Login Required</h1>
      <p>Please log in to access the dashboard.</p>
      <Button color="primary" onClick={() => window.location.href = 'https://clean-web.solutions/login'}>Go to Login</Button>
    </Container>
  );
};

export default LoginRequired;
